<template>
  <div>
    <div class="list-item" v-for="(item, i) in list" :key="i">
      <h2 @click="handleDetail(item)" v-if="item.dataName">
        {{ item.dataName }}
      </h2>
      <ul>
        <li>
          <label for="">填报人：</label>{{ item.submitter != null ? item.submitter : "无" }}
        </li>
        <li>
          <label for="">所属机构：</label>{{ item.submitInst != null ? item.submitInst : "无" }}
        </li>
        <li>
          <label for="">复核人：</label>{{ item.reviewer != null ? item.reviewer : "无" }}
        </li>
        <li>
          <label for="">复核机构：</label>{{ item.evaluationOrg != null ? item.auditAgency : "无" }}
        </li>
        <li>
          <label for="">数据大小：</label>{{ item.dataSize != null ? item.dataSize + "GB" : "无" }}
        </li>
        <li>
          <label for="">数据来源：</label>{{ item.source != null ? item.source : "无" }}
        </li>
        <li>
          <label for=""> 数据分类：</label>
          <div v-if="item.level == '重要数据' || item.level == '核心数据'" class="subject">
            <span> {{ item.first }}></span>
            <span>{{ item.second }}></span>
            <span>{{ item.third }}</span>
          </div>
          <div v-if="item.level == '一般数据'" class="subject">
            <Tooltip placement="top" max-width="300" theme="light">
              <template #content>
                <span v-for="(each, n) in item.dataType" :key="n">
                  <template v-if="n != item.dataType.length - 1">
                    <span> {{ item.first }}></span>
                    <span v-for="(m, t) in each" :key="t">
                      <span v-if="t != each.length - 1">{{ m }}></span>
                      <span v-else>{{ m }}</span> </span>；
                  </template>
                  <template v-else>
                    <span> {{ item.first }}></span>
                    <span v-for="(m, t) in each" :key="t">
                      <span v-if="t != each.length - 1">{{ m }}></span>
                      <span v-else>{{ m }}</span>
                    </span>
                  </template>
                </span>
              </template>
              <span v-for="(each, n) in item.dataType" :key="n">
                <template v-if="n != item.dataType.length - 1">
                  <span> {{ item.first }}></span>
                  <span v-for="(m, t) in each" :key="t">
                    <span v-if="t != each.length - 1">{{ m }}></span>
                    <span v-else>{{ m }}</span> </span>；
                </template>
                <template v-else>
                  <span> {{ item.first }}></span>
                  <span v-for="(m, t) in each" :key="t">
                    <span v-if="t != each.length - 1">{{ m }}></span>
                    <span v-else>{{ m }}</span>
                  </span>
                </template>
              </span>
            </Tooltip>
          </div>
        </li>
      </ul>
      <img src="../../../assets/img/一般签.png" alt="" v-if="item.level == '一般数据'" />
      <img src="../../../assets/img/重要签.png" alt="" v-if="item.level == '重要数据'" />
      <img src="../../../assets/img/核心签.png" alt="" v-if="item.level == '核心数据'" />
      <p class="date" v-if="item.level == '一般数据'">
        {{ item.auditDataTime }}
      </p>
      <p class="date" v-if="item.level == '重要数据' || item.level == '核心数据'">
        {{ item.auditPassData }}
      </p>
    </div>
    <div class="page" v-if="total > 10">
      <Page :total="total" show-elevator @on-change="changePage" :page-size="pageSize" prev-text="上一页"
        next-text="下一页" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, watch, onBeforeUnmount, nextTick } from "vue";
import { useRouter } from "vue-router";
import bus from "@/utils/bus.js";
let router = useRouter();
let axios = inject("axios");
let total = ref(0);
let page = ref(1);
let pageSize = ref(10);

let keyword = ref(""); //检索关键词
let Organization = ref([]); //所属机构
let auditAgency = ref([]); //复核机构
let level = ref([]);
let type = ref([]);
let year = ref([]);
let sort = ref("auditDataTime"); //排序条件

let list = ref([]);
//切换分页
const changePage = (val) => {
  // alert(1)
  // window.scrollTo(0, 0);
  page.value = val;
  getTableData();
  bus.emit("page", page.value);
  bus.emit("wrap-content");
};
//查询数据
const getTableData = () => {
  let url = `/data/list`;
  let params = {
    pageOffset: page.value,
    pageSize: pageSize.value,
    auditAgency: auditAgency.value.join(","),
    dataName: keyword.value.replace(/%/g, '%25'),
    level: level.value.join(","),
    org: Organization.value.join(","),
    years: year.value.join(","),
    firsts: "",
    seconds: "",
    thirds: "",
    sort: sort.value,
  };

  let typeData = type.value;
  let first = [],
    second = [],
    third = [];
  typeData.forEach((item) => {
    if (item.level == 1) {
      first.push(item._id);
    }
    if (item.level == 2) {
      second.push(item._id);
    }
    if (item.level == 3) {
      third.push(item._id);
    }
  });

  params.firsts = first.length > 0 ? first.join(",") : "";
  params.seconds = second.length > 0 ? second.join(",") : "";
  params.thirds = third.length > 0 ? third.join(",") : "";
  axios.get(url, { params }).then((res) => {
    //  console.log(res);
    if (res.data.code == 200) {
      let data = res.data.data;
      list.value = data.content.map((item) => {
        return {
          ...item,
          // reviewer: item.reviewer != null ? item.reviewer.join("；") : null,
          second: Array.isArray(item.second) ? item.second.join(",") : null,
          third: Array.isArray(item.third) ? item.third.join(",") : null,
          reviewer: Array.isArray(item.reviewer)
            ? item.reviewer.join("；")
            : null,
          auditAgency: Array.isArray(item.auditAgency)
            ? item.auditAgency.join("；")
            : null,
        };
      });
      total.value = data.count;
      bus.emit("noticeTotal", total.value);
    }
  });
};
//数组对象去重
const filterData = (arr) => {
  let newArr = [];
  let obj = {};
  for (var i = 0; i < arr.length; i++) {
    if (!obj[arr[i]._id]) {
      newArr.push(arr[i]);
      obj[arr[i].key] = true;
    }
  }
  return newArr;
};
//跳转详情
const handleDetail = (row) => {
  if (row.level == "重要数据" || row.level == "核心数据") {
    router.push({
      name: "direDetails",
      query: {
        id: row.id,
      },
    });
  } else {
    router.push({
      name: "direComDetails",
      query: {
        id: row.id,
      },
    });
  }
};
const handleSearch = () => {
  page.value = 1;
  //点击搜索时同时查询左侧刷选条件和右侧列表
  getTableData();
};

const analyse = (data) => {
  let { name, value } = data;
  switch (name) {
    case "organization":
      Organization.value = value
        .filter((item) => {
          return item.isChecked;
        })
        .map((item) => {
          return item._id;
        });
      break;
    case "evaluationOrg":
      auditAgency.value = value
        .filter((item) => {
          return item.isChecked;
        })
        .map((item) => {
          return item._id;
        });
      break;
    case "levelData":
      level.value = value
        .filter((item) => {
          return item.isChecked;
        })
        .map((item) => {
          return item._id;
        });
      break;
    case "typeData":
      type.value = value.filter((item) => {
        return item.isChecked;
      });

      break;
    case "yearsData":
      year.value = value
        .filter((item) => {
          return item.isChecked;
        })
        .map((item) => {
          return item._id;
        });
      break;
  }
  // if(name =="organization" &&  Organization.value.length == 0){
  //   initOrg();
  // }
};

watch(
  () => Organization.value,
  (val) => {
    handleSearch();
  }
);
watch(
  () => auditAgency.value,
  (val) => {
    handleSearch();
  }
);
watch(
  () => level.value,
  (val) => {
    handleSearch();
  }
);
watch(
  () => type.value,
  (val) => {
    handleSearch();
  },
  { deep: true }
);
watch(
  () => year.value,
  (val) => {
    handleSearch();
  }
);
//设置初始化机构信息
const initOrg = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    let role = user.userInfo.role;
    if (role == 'general') {
      Organization.value = [user.org];
    }
  }
}
onMounted(() => {

  //接收左侧删除条件，并开始搜索
  bus.on("sendData1", (data) => {

    analyse(data);
  });
  //头部组件点击搜索
  bus.on("on-search", () => {
    handleSearch();
  });

  //接收头部组件传递过来的新的关键词
  bus.on("new-keyword", (val) => {
    keyword.value = val;
  });
  //接收排序条件
  bus.on("sort", (name) => {
    sort.value = name;
  });
  // nextTick(()=>{
  //   initOrg();
  // })
  handleSearch();
});
onBeforeUnmount(() => {
  bus.all.delete("sendData1");
  bus.all.delete("on-search");
  bus.all.delete("new-keyword");
  bus.all.delete("sort");
});
</script>

<style lang="scss" scoped>
.list-item {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding: 30px 0;
  h2 {
    font-family: PingFangSC-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 46px;
    color: #111111;
    margin-bottom: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    width: 90%;
  }
  h2:hover {
    cursor: pointer;
    color: #3d55a8;
    font-size: 20px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 30px;
      color: #888888;
      margin-right: 50px;
      display: -webkit-box;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      label {
        white-space: nowrap;
        min-width: 75px;
        display: inline-block;
      }
    }
    li:nth-child(1),
    li:nth-child(4) {
      width: 30%;
    }
    li:nth-child(2),
    li:nth-child(5) {
      width: 30%;
    }
    li:nth-child(3),
    li:nth-child(6) {
      width: 30%;
      margin-right: 0;
    }
  }
  > img {
    display: block;
    width: 50px;
    height: 48px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 10;
  }
  .date {
    position: absolute;
    bottom: 30px;
    right: 0;
    color: #888888;
  }
}
.list-item:last-child {
  border-bottom: 0;
}
.subject {
  :deep(.ivu-tooltip) {
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>